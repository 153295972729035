'use client';

import Script from 'next/script';
import { useEffect, useState } from 'react';

export const ZendeskLoader = ({
  isProductionBuild,
  zendeskSnippetKey,
}: {
  isProductionBuild: boolean;
  zendeskSnippetKey: string | undefined;
}) => {
  const [shouldLoadZendesk, setShouldLoadZendesk] = useState(false);

  useEffect(() => {
    if (!isProductionBuild || !zendeskSnippetKey) {
      return;
    }

    window.zESettings = {
      webWidget: {
        chat: {
          connectOnPageLoad: false,
        },
      },
    };

    setShouldLoadZendesk(true);
  }, [isProductionBuild, zendeskSnippetKey]);

  return (
    shouldLoadZendesk && (
      <Script
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskSnippetKey}`}
      />
    )
  );
};
